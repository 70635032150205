.suntimesContainer {
  background-color: var(--lightBlue);
  padding: 20px 16px;
}

.suntimesHeading {
  font-size: 2.2rem;
  margin-bottom: 20px;
}
.suntimes {
  display: flex;
}

.suntime {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  color: var(--waterBlue);

  img {
    margin-bottom: 10px;
  }
}
